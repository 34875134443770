import {globalHistory} from '@reach/router';
import * as queryString from 'query-string';
import React from 'react';

import {scrubUrl} from '../../lib/gatsby/PIIHelpers/PIIHelpers';

// Gatsby types plugin options as "unknown"
// play nice with that and check types within component
interface MetaPixelProps {
  pixelId: unknown;
  siteUrl: unknown;
}

// combine these values into a single object so they are updated at the same time and there should only be one rerender
interface MetaPixelState {
  counter: number;
  uri: string;
}

const MetaPixel: React.FC<MetaPixelProps> = props => {
  const [state, setState] = React.useState<MetaPixelState | undefined>(
    undefined,
  );

  React.useEffect(() => {
    setState({
      counter: 0,
      uri: scrubUrl(window.location),
    });

    return globalHistory.listen(listener => {
      setState(prevState => ({
        counter: (prevState?.counter ?? 0) + 1,
        uri: scrubUrl(listener.location),
      }));
    });
  }, []);

  if (
    typeof props.pixelId !== 'string' ||
    typeof props.siteUrl !== 'string' ||
    !state
  ) {
    return null;
  }

  return (
    <img
      height={1}
      // ensures that component will re-render with each history change
      key={`MetaPixel-${state.counter}`}
      src={`https://www.facebook.com/tr?${queryString.stringify({
        dl: props.siteUrl + state.uri,
        ev: state.counter == 0 ? 'PageView' : 'ViewContent',
        id: props.pixelId,
      })}`}
      style={{display: 'none'}}
      width={1}
      alt=""
    />
  );
};

export default MetaPixel;
