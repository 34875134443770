interface Languages {
  code: string;
  path: string;
  name: string;
  label: string;
}

interface PrismicDocument {
  uid?: string;
  lang?: string;
  type?: string;
  alternate_languages?: PrismicDocument[];
}

const pathMap: Array<{
  id: string;
  name: string;
  path: string;
  uid: string;
}> = require('./path-map.json');

/**
 * Retrieve full path for the given document from the generated Prismic path map.
 */
const getFullPath = (uid: string): string => {
  const page = pathMap.find(doc => doc.uid === uid);
  if (!page) {
    console.warn('Could not locate page in path map', uid);
    return uid;
  }
  return page.path;
};

/** Retrieve the translated version of a page for the given language. */
const getTranslatedPage = (page: PrismicDocument, lang: string): PrismicDocument =>
  page.alternate_languages
    ? page.alternate_languages.find(altPage => altPage.lang === lang)
    : null;

/** Retrieve a list of translated versions of the given page. */
export const getTranslationsForPage = (page: PrismicDocument, languages: Languages[]) =>
  languages
    .map(lang => {
      let href;
      if (page.lang === lang.code && page.uid) {
        href = resolvePrismicLink(page, languages);
      } else {
        const translation = getTranslatedPage(page, lang.code);
        if (translation && translation.uid) {
          href = resolvePrismicLink(translation, languages);
        }
      }
      return href ? {href, lang} : null;
    })
    .filter(trans => trans !== null);
  
export const LANDING_PAGE_PREFIX = "lp";

/** Resolve a Prismic page link for the doc and available languages. */
export const resolvePrismicLink = (doc: PrismicDocument, languages: Languages[]): string => {
  if (!doc.uid) {
    return;
  }
  const lang = languages.find(lang => lang.code === doc.lang);
  let docPath = doc.uid;
  switch (doc.type) {
    case 'page':
      docPath = getFullPath(doc.uid);
      break;
    case 'article':
      // Ideally we'd use i18next here, but the use of `i18next-loader` / Webpack for loading
      // translation files prevents it from working properly for initial SSR outside of React.
      // TODO: Investigate alternative approach to loading `i18n` translations that does work here.
      const articleBase = lang.code === 'es-mx' ? 'recursos' : 'resources';
      docPath = `${articleBase}/${doc.uid}/`;
      break;
    case 'plan_location':
      const planLocationBase =
        lang.code === 'es-mx' ? 'encontrar-un-plan' : 'find-a-plan';
      docPath = `${planLocationBase}/${doc.uid}/`;
      break;
    case 'plan':
      const planBase = lang.code === 'es-mx' ? 'inscribirse' : 'enroll';
      docPath = `${planBase}/${doc.uid}/`;
      break;
    case 'marketing_landing_page':
      docPath = `${LANDING_PAGE_PREFIX}/${doc.uid}`
      break;
  }
  return `${lang.path}/${docPath}`;
};
