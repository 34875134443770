import * as queryString from 'query-string';

const isNotPlanIdFilter = (s: string) =>
  !/H[0-9]{4}-[0-9]{3}-[0-9]{3}-[0-9]{4}/.test(s);

const removePlanIdFromPathname = (url: string) => {
  const urlCopy = `${url}`;
  return urlCopy.split('/').filter(isNotPlanIdFilter).join('/');
};

const removePlanIdFromSearch = (searchUrl: string) => {
  const parsedSearchParams = queryString.parse(searchUrl);
  Object.keys(parsedSearchParams).forEach(key => {
    if (!isNotPlanIdFilter(key)) {
      delete parsedSearchParams[key];
    }
  });
  const stringSearchParams = queryString.stringify(parsedSearchParams);
  return stringSearchParams ? `?${stringSearchParams}` : '';
};

const removeUrlParametersFromHash = (hashUrl: string) => {
  // qs params q contains names of doctors, s contains the state the user is applying for a pbp for, and zip contains the zip code of the user. All are PII, we don't need to send to any event tracking service (e.g. facebook/google)
  const parsed = queryString.parse(hashUrl);
  ['filters', 'filterType', 'p', 'q', 's', 'zip'].forEach(key => {
    delete parsed[key];
  });

  const qs = queryString.stringify(parsed);

  return qs ? `#${qs}` : qs;
};

export interface scrubUrlInterface {
  pathname: string;
  search: string;
  hash: string;
}

export const scrubUrl = (location: scrubUrlInterface) => {
  return (
    removePlanIdFromPathname(location.pathname) +
    removePlanIdFromSearch(location.search) +
    removeUrlParametersFromHash(location.hash)
  );
};
