// this file should mirror gatsby-ssr.tsx
import {
  PrismicPreviewProvider,
  RepositoryConfig,
} from 'gatsby-plugin-prismic-previews';
import * as React from 'react';
import {hydrateRoot} from 'react-dom/client';
import {I18nextProvider} from 'react-i18next';

import i18n from '@src/i18n';

import {scrubUrl} from './lib/gatsby/PIIHelpers/PIIHelpers';
import {resolvePrismicLink} from './lib/prismic';

import type {GatsbyBrowser} from 'gatsby';

declare global {
  interface Window {
    excludeGtagPaths?: RegExp[];
    gtag?: (
      event: string,
      action: string,
      options: Record<string, string>,
    ) => void;
  }
}

const languages = require('./lib/gatsby/data/languages.json');

const sendGooglePageView = (location: Location) => {
  if (
    process.env.NODE_ENV !== 'production' ||
    typeof window.gtag !== 'function'
  ) {
    return;
  }

  const pathIsExcluded =
    location &&
    window.excludeGtagPaths &&
    window.excludeGtagPaths.some(rx => {
      return rx.test(location.pathname);
    });
  // wrap inside a timeout to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/11592)
  if (pathIsExcluded) {
    return;
  }

  const sendPageView = () => {
    const pagePath = location ? scrubUrl(location) : undefined;
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_path: pagePath,
      });
    }
  };

  if ('requestAnimationFrame' in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(sendPageView);
    });
  } else {
    // simulate 2 rAF calls
    setTimeout(sendPageView, 32);
  }
};

export const onInitialClientRender: GatsbyBrowser['onInitialClientRender'] =
  () => {
    const docElement = document.documentElement;

    // Change `no-js` to `js` (via Modernizr)
    const reJS = new RegExp('(^|\\s)no-js(\\s|$)');
    docElement.className = docElement.className.replace(reJS, '$1js$2');
  };

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({
  action,
  location,
}) => {
  sendGooglePageView(location);

  // Ensure scroll resets when clicking back button from anchor link
  if (action === 'POP') {
    window.scrollTo(0, 0);
  }
};

// @ts-ignore
export const replaceHydrateFunction: GatsbyBrowser['replaceHydrateFunction'] =
  () => {
    return (element: React.ReactNode, container: Document | Element) => {
      const root = hydrateRoot(container, element, {
        onRecoverableError: (error, errorInfo) => {
          if (
            // these first two conditions are really to appease TypeScript
            // we should be able to assume both of these things
            !(error instanceof Error) ||
            !window.Rollbar ||
            !window.DD_RUM ||
            // hydration errors became endemic after upgrading to React 18
            // they appear to all be related to CallRail (see the callrail-dni plugin)
            // we don't control this JS, they don't offer another approach, and we'd rather have the upgrade
            /Minified React error #(418|422|425);/.test(error.message)
          ) {
            return;
          }

          if (errorInfo && errorInfo.componentStack) {
            window.Rollbar.error(error.message + errorInfo.componentStack);
          } else {
            window.Rollbar.error(error.message);
          }
          window.DD_RUM.addError(error);
        },
      });

      return () => root.unmount();
    };
  };

// gatsby-source-prismic docs has two recommendations for performance:
// - defining this array in a constant outside the wrapRootElement() function
// - using React.lazy() to ensure that bundles doe not include the code for each template on every page
const repositoryConfigs: RepositoryConfig[] = [
  {
    componentResolver: {
      article: React.lazy(() => import('./src/templates/Article')),
      marketing_landing_page: React.lazy(
        () => import('./src/templates/MarketingLandingPage'),
      ),
      page: React.lazy(() => import('./src/templates/BasicPage')),
    },
    linkResolver: doc => resolvePrismicLink(doc, languages),
    // gatsby-config.ts gets this value from process.env
    // hardcode it here because it won't be set in the built artifacts that end users download
    repositoryName: 'devoted-health-2020',
  },
];

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => (
  <I18nextProvider i18n={i18n}>
    <React.Suspense
      // mimic src/pages/preview without the explicit dependency
      fallback={
        <div>
          <h1>Loading preview…</h1>
        </div>
      }>
      <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
        {element}
      </PrismicPreviewProvider>
    </React.Suspense>
  </I18nextProvider>
);
