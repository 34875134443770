import React from 'react';

import MetaPixel from './MetaPixel';

import type {GatsbyBrowser} from 'gatsby';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = (
  {element},
  options,
) => {
  return (
    <>
      {element}
      <MetaPixel pixelId={options.pixelId} siteUrl={options.siteUrl} />
    </>
  );
};
