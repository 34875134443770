module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"devoted-health-2020"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.devoted.com","stripQueryString":true},
    },{
      plugin: require('../plugins/callrail-dni/gatsby-browser.ts'),
      options: {"plugins":[],"src":"//cdn.callrail.com/companies/486691625/5bccb1fc1a352f339c3d/12/swap.js"},
    },{
      plugin: require('../plugins/devoted-meta-pixel/gatsby-browser.tsx'),
      options: {"plugins":[],"pixelId":"2087880174792717","siteUrl":"https://www.devoted.com"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
